export const getVacancyIdFromPath = (path) => {
  // We get some request like '/wp-content/uploads/2020/01/cropped-favicon-student-care-32x32-1.png' that contain numbers,
  // so we need this check to not treat them as vacancy pages
  if (!path?.includes('wp-content/uploads')) {
    const captureNumericId = /\/(\d+)(\/|$)/
    const match = path.match(captureNumericId)
    if (match && match[1]) {
      return match[1]
    }
  }
}

// TODO Replace with a block in storyblok that takes care of getting its own token from the queryparms instead of passing it down the context.
export const getJobAlertTokenFromPath = (path) => {
  const url = new URL(path, 'http://localhost') // We don't care about the origin
  if (url.pathname.includes('/unsubscribe')) {
    return url.searchParams.get('token')
  }

  return null
}
