const STORYBLOK_API_URL = 'https://a.storyblok.com'
const s3BaseUrl =
  /https:\/\/s3-eu-west-1.amazonaws.com\/jobsrepublic-too-images(-test|-dev)?\/|https:\/\/jobsrepublic-too-images(-test|-dev)?.s3.eu-west-1.amazonaws.com\//
const storyblokBaseUrl = /https:\/\/a\.storyblok\.com\//

/**
 *
 * @param {Object}
 * imgUrl: url String
 * @returns parsed url String
 */
export function getImgSrc({ imgUrl, ...options }) {
  if (!imgUrl) return ''

  let url = imgUrl
  if (s3BaseUrl.test(imgUrl)) {
    url = getImgSrcS3({ imgUrl, ...options })
  }

  if (storyblokBaseUrl.test(imgUrl)) {
    url = getImgSrcStoryblok({ imgUrl, ...options })
  }

  return encodeURI(url)
}

/**
 *
 * @param {Object}
 * imgUrl: url String
 * width, height: numeric pixel value
 * quality: fractionary numeric value (from 0 to 1)
 * trim: String see imgix docs
 * trimcolor: String see imgix docs
 * @returns parsed url string
 */
function getImgSrcS3({
  imgUrl,
  width,
  height,
  quality,
  trim, // 'color', 'auto'
  trimcolor, // 'ffffff'
  smart = false,
  fit,
  crop,
}) {
  const queryParams = []

  if (typeof width !== 'undefined') {
    queryParams.push(`w=${width}`)
  }

  if (typeof height !== 'undefined') {
    queryParams.push(`h=${height}`)
  }
  if (typeof quality !== 'undefined') {
    queryParams.push(`q=${quality}`)
  }
  if (typeof trim !== 'undefined') {
    queryParams.push(`trim=${trim}`)
  }
  if (typeof trimcolor !== 'undefined') {
    queryParams.push(`trimcolor=${trimcolor}`)
  }
  if (typeof fit !== 'undefined') {
    queryParams.push(`fit=${fit}`)
  }
  if (typeof crop !== 'undefined') {
    queryParams.push(`crop=${crop}`)
  }

  if (smart) {
    queryParams.push('fit=facearea')
  }

  const queryString = queryParams.length
    ? `&${queryParams.sort().join('&')}`
    : ''

  return `${imgUrl.replace(
    s3BaseUrl,
    'https://jobsrepublic-too-images$1$2.imgix.net/',
  )}?auto=format,compress${queryString}`
}

/**
 *
 * @param {Object}
 * imgUrl: url String
 * width, height: numeric pixel value
 * quality: fractionary numeric value (from 0 to 1)
 * @returns parsed url string
 */
function getImgSrcStoryblok({
  imgUrl,
  width = 0,
  height = 0,
  quality,
  smart = false,
}) {
  const base = STORYBLOK_API_URL
  const resource = imgUrl.replace(storyblokBaseUrl, '')
  const urlParts = [base, resource]

  const optimizeImg = !resource.endsWith('svg')
  if (optimizeImg) {
    const params = []
    if (width || height) {
      params.push(`${width}x${height}`)
    }

    if (smart) {
      params.push('smart')
    }

    if (typeof quality !== 'undefined') {
      const convertedQuality = Math.max(
        Math.min(Math.floor(quality * 100), 100),
        0,
      )
      params.push(`filters:quality(${convertedQuality})`)
    }

    // We need to end the url with / in case there are no params
    if (!params.length) {
      params.push('')
    }

    urlParts.push('m', ...params)
  }

  return urlParts.join('/')
}

/**
 *
 * @param {Object}
 * imgUrl: url String
 * widths: Array of width numbers (in pixels) or objects with the form { width: Number, screen: Number }
 * @returns String apt to be used in img srcset property
 */
export function getImgSrcset({
  imgUrl,
  widths = [640, 1024, 1920, 2048],
  ...options
}) {
  return widths
    .map((width) => {
      if (typeof width === 'number') {
        return `${getImgSrc({ imgUrl, width, ...options })} ${width}w`
      }
      const { width: imgWidth, screen } = width
      return `${getImgSrc({ imgUrl, width: imgWidth, ...options })} ${screen}w`
    })
    .join(', ')
}

export function getFileNameFromUrl(imgUrl) {
  return imgUrl.split('?').shift().split('/').pop().split('.').shift()
}

/**
 *
 * @param {Array} sourceTypes Array of either urls or storyblok images, the first one will be the one picked up
 */
export function getValidImageSrc(sourceTypes) {
  let src = null

  for (const imgSrc of sourceTypes) {
    if (imgSrc && imgSrc?.filename) {
      src = imgSrc?.filename
      break
    }
    if (typeof imgSrc === 'string' && imgSrc) {
      src = imgSrc
      break
    }
  }

  return src
}
