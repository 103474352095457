export const generateFontObject = (theme) => {
  const titleFont = getName(theme?.titleFont) || 'Roboto'
  const subtitleFont = getName(theme?.subtitleFont) || 'Roboto'
  const contentFont = getName(theme?.contentFont) || 'Open Sans'
  const fontWeight = getFontWeight(theme?.titleFont) || 'inherit'

  return {
    '--heading': titleFont,
    '--subheading': subtitleFont,
    '--content': contentFont,
    '--heading-weight': fontWeight,
    fontSourceFiles: [
      { name: titleFont, data: theme?.titleFont },
      { name: subtitleFont, data: theme?.subtitleFont },
      { name: contentFont, data: theme?.contentFont }
    ]
  }
}

export const getGoogleFontLinks = (fontName, fontVariant = '') => {
  // We add a preload <link> so the browser starts fetching the font right away
  // and a regular <link> at the end of <body> so it doesn't block rendering
  const fontLink = fontName.split(',')[0].replace(/['"]/g, '')
  return [
    {
      rel: 'preload',
      as: 'style',
      href: `https://fonts.googleapis.com/css?family=${fontLink.replace(
        /\s/g,
        '+'
      )}:${fontVariant}&display=swap`
    },
    {
      rel: 'stylesheet',
      href: `https://fonts.googleapis.com/css?family=${fontLink.replace(
        /\s/g,
        '+'
      )}:${fontVariant}&display=swap`,
      tagPosition: 'bodyClose'
    }
  ]
}

export const getCustomFontStyle = (fontName, sourceUrls, nonce) => {
  // We need to do this to fix a CORS issue, details:
  // https://www.storyblok.com/faq/xmlhttprequest-cannot-load-no-access-control-allow-origin-header
  const s3Urls = sourceUrls.map((source) =>
    source.replace(
      'https://a.storyblok.com',
      'https://s3.amazonaws.com/a.storyblok.com'
    )
  )

  return {
    key: fontName,
    nonce,
    innerHTML: `
      @font-face {
        font-family: ${fontName};
        src: ${s3Urls.map((url) => `url('${url}')`).join(',')};
        font-display: swap;
      }`
  }
}

export const createCustomFont = (sourceFiles) => {
  let urls = ''
  const fontName = sourceFiles[0].split('/').pop().split('.')[0]
  sourceFiles.forEach((sourceFile) => {
    urls += `url(${sourceFile}), `
  })
  urls = urls.substr(0, urls.length - 2)
  return new FontFace(fontName, urls)
}

const getName = (font) => {
  if (font?.fontType === 'googleFont') {
    return font?.googleFont?.family
  }
  if (font.localFonts?.length) {
    const localFontName = font?.localFonts[0].split('/').pop()
    return localFontName.split('.')[0]
  }
  return null
}

const getFontWeight = (font) => {
  if (font?.fontType !== 'googleFont' || !font.googleFontVariant) {
    return null
  }
  const weight = font.googleFontVariant.substr(0, 3)
  return isNaN(weight) ? null : weight
}
