import fetch from 'cross-fetch'

export const checkStatusCode = async (response, url) => {
  if (response.status >= 200 && response.status < 400) {
    return response
  }

  const contentType = response.headers.get('content-type')
  if (contentType && contentType.includes('application/json')) {
    const { error } = await response.json()

    let errorMessage
    if (typeof error === 'string') {
      errorMessage = error
    } else {
      errorMessage = error.title
      if (error.detail) {
        errorMessage += `: ${error.detail}`
      }
    }

    console.log(`Request to '${url}' returned an error`)
    throw new Error(errorMessage)
  }

  // We print the entire response to try to determine what's the issue
  console.dir(response, { depth: null, showHidden: true })
  console.log(`Response headers:`)
  response.headers.forEach((value, key) => {
    console.log(`${key}: ${value}`)
  })

  console.log(`Request to '${url}' returned an error`)
  throw new Error(response.status)
}

export const makeRequest = async ({ url, requestOptions }) => {
  let response = await fetch(url, requestOptions)

  response = await checkStatusCode(response, url)
  const contentType = response.headers.get('content-type')

  if (contentType && contentType.includes('application/json')) {
    return response.json()
  }
  return {}
}
