// import 'cross-fetch/polyfill'
import { useStoryblokApi } from '@storyblok/vue'
import { render } from 'vike/abort'
import { generateFontObject } from '../utils/fontUtils'

export async function fetchStoryblokContent(pageContext) {
  const { domainSlug, path, fallbackPath, storyblokVersion, vacancyId } =
    pageContext

  let story
  if (vacancyId) {
    story = await getVacancyPage({
      vacancyId,
      domainSlug,
      path,
      storyblokVersion,
    })

    if (!story) {
      throw new Error('Vacancy page not found!')
    }
  } else {
    // Try loading the story
    try {
      const { data } = await useStoryblokApi().get(
        `cdn/stories/${domainSlug}${path}`,
        {
          version: storyblokVersion,
          resolve_relations: 'GlobalReference.reference',
          cv: new Date().getTime(),
        },
      )
      story = data.story
    } catch (error) {
      // If the story isn't found try the fallback path
      const { status } = error
      if (status === 404 && fallbackPath) {
        try {
          const { data } = await useStoryblokApi().get(
            `cdn/stories/${domainSlug}${fallbackPath}`,
            {
              version: storyblokVersion,
              resolve_relations: 'GlobalReference.reference',
              cv: new Date().getTime(),
            },
          )
          story = data.story
        } catch (error2) {
          // We couldn't find the fallback page, show the error page instead
          redirectToErrorPage(error2, domainSlug, path)
        }
      } else {
        // If that doesn't work throw and let the parent application handle the error
        redirectToErrorPage(error, domainSlug, path)
      }
    }
  }

  const content = story?.content
  const blocks = resolveGlobalReferences(content?.blocks)
  return { ...content, blocks, id: story?.id }
}

export const getVacancyPage = async ({
  vacancyId,
  domainSlug,
  path,
  storyblokVersion = 'published',
}) => {
  if (!vacancyId || !domainSlug || !path) {
    return null
  }

  const defaultTemplateSlug = `${domainSlug}/_default/_vacancy`
  const slugs = [defaultTemplateSlug]

  let slugWithVacancyId = ''
  if (path.includes(vacancyId)) {
    // We need to remove the vacancy slug for searching the specific page on Storyblok
    slugWithVacancyId = `${domainSlug}${path}`.split(vacancyId)[0] + vacancyId
    slugs.push(slugWithVacancyId)
  }

  // For fetching a specific template for this vacancy, we get the string after the last / of the URL
  let targetingGroupSlug = ''
  let pathSegments = path.split('/')
  // We remove empty elements of the array (result of the path beginning or ending in '/')
  pathSegments = pathSegments.filter((item) => item)
  if (pathSegments.length) {
    const lastSegment = pathSegments[pathSegments.length - 1]
    if (lastSegment !== vacancyId.toString()) {
      targetingGroupSlug = `${domainSlug}/_default/_vacancy_${lastSegment}`
      slugs.push(targetingGroupSlug)
    }
  }

  // We also get the homepage of the domain to make sure the site is online
  slugs.push(`${domainSlug}/`)

  const { data } = await useStoryblokApi().get('cdn/stories', {
    version: storyblokVersion,
    resolve_relations: 'GlobalReference.reference',
    by_slugs: slugs.join(','),
    cv: new Date().getTime(),
  })
  const stories = data.stories

  if (stories.length) {
    let page = null
    if (slugWithVacancyId) {
      page = stories.find((story) => story.full_slug === slugWithVacancyId)
    }
    if (!page && targetingGroupSlug) {
      page = stories.find((story) => story.full_slug === targetingGroupSlug)
    }
    if (!page) {
      page = stories.find((story) => story.full_slug === defaultTemplateSlug)
    }

    return page
  } else {
    // If not even the homepage of the site is online, handle it as a regular 404
    redirectToErrorPage(
      { status: 404, message: 'Entire site is offline' },
      domainSlug,
      path,
    )
  }
}

export const getTheme = async ({
  domainSlug = '',
  storyblokVersion = 'published',
}) => {
  try {
    const { data } = await useStoryblokApi().get(
      `cdn/stories/${domainSlug}/_theme`,
      {
        version: storyblokVersion,
        resolve_relations:
          'theme.titleFont,theme.contentFont,theme.subtitleFont',
        cv: new Date().getTime(),
      },
    )
    const theme = data.story?.content

    if (!theme) {
      return
    }

    const themeFonts = generateFontObject(theme)
    return {
      ...theme.themeColors.colors,
      ...themeFonts,
      palette: theme.palette,
      dontUseDeprecatedColors: theme.dontUseDeprecatedColors,
      locale: theme.locale || 'nl',
      notFormatCurrency: theme.notFormatCurrency,
    }
  } catch (e) {
    console.warn(`No _theme found for site ${domainSlug}`)
  }
}

export function resolveGlobalReferences(blocks) {
  if (!blocks || !blocks.length) return []

  const result = []
  blocks.forEach((block) => {
    if (block.component === 'GlobalReference') {
      // We add the dynamic values defined for the global block to all its children
      let variables = {}
      const fields = block.variables?.split('\n') || []
      if (fields.length && fields[0]) {
        variables = fields.reduce((acc, nextField) => {
          const [name, value] = nextField.split('|')
          if (name) {
            acc[name.trim()] = value ? value.trim() : null
          }
          return acc
        }, {})
      }

      // Gets all blocks inside the GlobalReference and adds them to the block list
      const referencedBlocks = (block.reference.content?.blocks || []).map(
        (globalBlock) => ({
          ...globalBlock,
          isGlobal: true,
          reference: block._uid,
          variables,
        }),
      )
      result.push(...referencedBlocks)
    } else {
      result.push(block)
    }
  })

  return result
}

const redirectToErrorPage = (error, domainSlug, path) => {
  console.error(
    `Error fetching content from Storyblok! Story: ${domainSlug}${path}`,
  )
  console.error(error)
  throw render(error.status, error.message)
}
