<template>
  <component
    :is="component"
    v-editable="isVisualEditor ? block : null"
    :blok="block"
    :context="context"
    class="font-content"
    :class="block.component"
  />
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { usePageContext } from '../renderer/usePageContext'

const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  context: {
    type: Object,
    default: () => {},
  },
})

const isVisualEditor = import.meta.env.VITE_IS_EDITOR

const pageContext = usePageContext()
const host = pageContext.hostname || window.location?.host
const currentLocation = `https://${host}${pageContext.urlOriginal}`

const component = defineAsyncComponent({
  // the loader function
  loader: async () => await import(`../blocks/${props.block.component}.vue`),
  // A component to use if the load fails
  errorComponent: 'div',
  async onError(error, retry, fail, attempts) {
    if (!isVisualEditor && pageContext.isHydration) {
      if (window.location.toString() !== currentLocation) {
        console.log(
          `Ignoring the following import error because the user moved to a different page:`,
        )
        console.log(error.toString())
      }
    } else {
      fail()
    }
  },
})
</script>
