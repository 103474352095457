export default function parseEntriesDeep ([key, value], callback) {
  let values = []

  if (value && typeof value === 'object') {
    Object.entries(value).forEach(([k, val]) => {
      values = [...values, ...parseEntriesDeep([k, val], callback)]
    })
    values = values.filter(el => typeof el !== 'undefined') // filter out falsy values
    return values
  }

  values.push(callback(key, value))
  return values
}
