<template>
  <div v-if="errors && errors.length" class="bg-red-50 p-4">
    <div class="flex">
      <div class="shrink-0">
        <XCircleIcon class="h-5 w-5 text-red-400" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          There are {{ errors.length }} errors with this page
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul class="list-disc space-y-1 pl-5">
            <li v-for="({ message }, index) in errors" :key="index">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { XCircleIcon } from '@heroicons/vue/20/solid'
import parseEntriesDeep from '../utils/parseEntriesDeep.js'

const props = defineProps({
  bloks: {
    type: Array,
    default: () => [],
  },
})

const errors = computed(() => {
  return [...sectionLinkErrors.value]
})
const sectionLinkErrors = computed(() => {
  if (!props.bloks || !props.bloks.length) {
    return []
  }
  // list available targets
  const availableSectionLinks = props.bloks
    .map((blok) => blok.sectionLinkId)
    .filter(Boolean)
  return props.bloks.reduce((errors, blok) => {
    const sectionLinks = parseEntriesDeep([null, blok], (key, value) => {
      if (key === 'sectionLink' && value !== '') {
        return value
      }
    })
    return [
      ...errors,
      ...sectionLinks
        .filter((sectionLink) => !availableSectionLinks.includes(sectionLink))
        .map((missingSectionLink) => ({
          message: `Block ${blok.component} links to non existing section ${missingSectionLink}`,
        })),
    ]
  }, [])
})
</script>
