<template>
  <div class="antialiased">
    <PageCheck v-if="isVisualEditor" :bloks="content.blocks" />

    <!-- HEADER SECTION BEGIN -->
    <template v-if="siteHeader">
      <a
        href="#mainContent"
        class="absolute -top-96 z-50 rounded-br-lg bg-default px-2 py-1 font-medium text-white focus:top-0"
      >
        {{ skipNavigationLabel }}
      </a>
      <PageBlock :block="siteHeader" :context="context" />
    </template>
    <!-- HEADER SECTION END -->

    <main id="mainContent">
      <PageBlock
        v-for="currentBlok in visibleBlocks"
        :key="currentBlok._uid"
        :block="currentBlok"
        :context="context"
      />
    </main>

    <!-- FOOTER SECTION BEGIN -->
    <PageBlock v-if="siteFooter" :block="siteFooter" :context="context" />
    <!-- FOOTER SECTION END -->

    <PageMetadataOverview v-if="isVisualEditor" :context="context" />
  </div>
</template>

<script setup>
import PageCheck from '../components/PageCheck.vue'
import PageBlock from '../components/PageBlock.vue'

import { ref, computed, watchEffect, onMounted } from 'vue'
import { useStoryblokBridge } from '@storyblok/vue'
import { useHead, useSeoMeta } from '@unhead/vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '../store'
import replaceBlock from '../utils/replaceBlock.js'
import PageMetadataOverview from '../components/PageMetadataOverview.vue'
import { getValidImageSrc } from '../utils/imgUtils'

const store = useMainStore()
const { pageContent, pageContext, vacancy, company, publication, site } =
  storeToRefs(store)

const isVisualEditor = import.meta.env.VITE_IS_EDITOR
const editorContent = ref(pageContent)

const visibleBlocks = computed(() => {
  return content.value.blocks.filter(
    (item) =>
      item._uid !== siteHeader.value?._uid &&
      item._uid !== siteFooter.value?._uid,
  )
})
const context = computed(() => {
  const contextToUse = {
    ...pageContext.value,
    vacancy: vacancy.value,
    company: company.value,
    publication: publication.value,
    site: site.value,
    // TODO Add user when it's available
  }

  return {
    ...contextToUse,
    // This prop was renamed but we keep the old one to be backwards compatible,
    // otherwise we have to change old pages on Storyblok that were using it.
    publications: contextToUse.publication,
    currentYear: new Date().getFullYear(),
  }
})
const content = computed(() => {
  let content
  if (isVisualEditor) {
    content = editorContent.value
  } else {
    content = pageContent.value || { blocks: [] }
  }
  let blocks = content.blocks || []

  // Remove expired vacancy modal if it's present and we're not on editor mode and the vacancy is not expired
  // This is needed so Indeed doesn't mark our vacancies as closed
  // Extremely hacky solution, an alternative would be to implement an XML feed of our vacancies
  // for Indeed: https://developer.indeed.com/docs/indeed-apply/direct-employer/
  if (!isVisualEditor && context.value.publication) {
    const vacancyExpired =
      Date.now() > Date.parse(context.value.publication?.endDate)

    if (!vacancyExpired) {
      blocks = blocks.filter(
        (block) => block.component !== 'ExpiredVacancyTextWithCallToAction',
      )
    }
  }

  // Replace placeholders in content (e.g. {{vacancy.title}}) by value from context (look at replaceBlock.spec.js)
  // TODO refactor getReplacedBlock to have content and context as args, instead of factory function
  let result = { ...content, blocks }
  const { getReplacedBlock } = replaceBlock(
    { context: context.value },
    isVisualEditor,
  )
  result = getReplacedBlock(result)

  return result
})
const siteHeader = computed(() => {
  return content.value.blocks.find(
    (item) => item.component === 'PageHeaderWithMenu',
  )
})
const siteFooter = computed(() => {
  return [...content.value.blocks]
    .reverse()
    .find((item) => item.component.startsWith('Footer'))
})
const skipNavigationLabel = computed(() => {
  return siteHeader.value?.skipNavigationLabel || 'Ga direct naar de content'
})

watchEffect(() => {
  const tooMetadata = context.value.vacancy?.metadata || {}
  // Metadata from Storyblok takes precedence over metadata from TOO
  const metadata = {
    ...tooMetadata,
    ...content.value,
  }

  const imgSourceTypes = []
  metadata.openGraphImage && imgSourceTypes.push(metadata.openGraphImage)
  metadata.openGraphImageUrl && imgSourceTypes.push(metadata.openGraphImageUrl)
  metadata.image = getValidImageSrc(imgSourceTypes)

  const seoMeta = {}
  if (metadata.title) {
    seoMeta.twitterCard = 'summary'
    seoMeta.twitterTitle = metadata.title
    seoMeta.ogTitle = metadata.title

    useHead({
      title: metadata.title,
    })
  }
  if (metadata.description) {
    seoMeta.description = metadata.description
    seoMeta.twitterDescription = metadata.description
    seoMeta.ogDescription = metadata.description
  }
  if (metadata.blockIndexing) {
    seoMeta.robots = 'noindex'
  }
  if (metadata.openGraphType) {
    seoMeta.ogType = metadata.openGraphType
  }
  if (metadata.image) {
    seoMeta.ogImage = metadata.image
  }

  if (seoMeta) {
    useSeoMeta(seoMeta)
  }
})

if (isVisualEditor) {
  onMounted(() => {
    useStoryblokBridge(editorContent.value.id, (story) => {
      console.log('Updating visual editor!')

      // We replace the GlobalReferences with the blocks we already had on page load
      // (this 'story' object doesn't contain the referenced block data)
      const blocks = []
      story.content.blocks.forEach((block) => {
        if (block.component === 'GlobalReference') {
          const referencedBlocks = editorContent.value.blocks.filter(
            (previousBlock) => previousBlock.reference === block._uid,
          )
          blocks.push(...referencedBlocks)
        } else {
          blocks.push(block)
        }
      })

      editorContent.value = { ...story.content, blocks }
    })
  })
}
</script>
