import fetch from 'cross-fetch'
import { checkStatusCode, makeRequest } from './helper.js'

const API_PATH = '/public-api/v2'

const getHeaders = ({ siteId }) => {
  return {
    'X-Site': siteId,
    'Content-Type': 'application/json;charset=UTF-8',
  }
}

export const fetchCompanyList = ({ siteId, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/companies`
  const myHeaders = getHeaders({ siteId })
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  return makeRequest({ url, requestOptions })
}

export const fetchCompany = ({
  companySlug,
  companyId,
  siteId,
  baseTooUrl,
}) => {
  const companyParam = companySlug || companyId
  const url = `${baseTooUrl}${API_PATH}/companies/${companyParam}`
  const myHeaders = getHeaders({ siteId })
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  }

  return makeRequest({ url, requestOptions })
}

export const fetchVacancy = ({ vacancyId, siteId, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/vacancies/${vacancyId}`
  const myHeaders = getHeaders({ siteId })
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  }

  return makeRequest({ url, requestOptions })
}

export const fetchVacancyList = ({ searchQuery, siteId, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/vacancies/summary/search`
  const myHeaders = getHeaders({ siteId })
  const body = JSON.stringify(searchQuery)
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body,
    redirect: 'follow',
  }

  return makeRequest({ url, requestOptions })
}

export const getVacancySearchFilters = ({ siteId, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/vacancy-details-allowed`
  const myHeaders = getHeaders({ siteId })
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  }

  return makeRequest({ url, requestOptions })
}

export const createJobAlert = ({ payload, siteId, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/jobalerts`
  const myHeaders = getHeaders({ siteId })
  const body = JSON.stringify(payload)
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body,
    redirect: 'follow',
  }

  return makeRequest({ url, requestOptions })
}

export const deleteJobAlert = ({ siteId, baseTooUrl, token }) => {
  if (!token) {
    throw new Error('Missing job alert token')
  }

  const url = `${baseTooUrl}${API_PATH}/jobalerts/${token}`
  const myHeaders = getHeaders({ siteId })
  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  }

  return makeRequest({ url, requestOptions })
}

export const vacancyNewsletterSignup = (payload) => {
  return new Promise((resolve, reject) => {
    return setTimeout(() => {
      console.warn('Attempt to subscribe to vacancy, but endpoint is not ready')
      resolve(true)
    }, 1000)
  })
}

/**
 *
 * @param {*} payload https://jobsrepublictoo.nl/swagger-ui.html#/vacancy-subscriber-controller-v-2/shareVacancyUsingPOST
 * @returns
 */
export const shareByEmail = async ({
  payload,
  siteId,
  vacancyId,
  baseTooUrl,
}) => {
  const url = `${baseTooUrl}${API_PATH}/vacancies/${vacancyId}/share`
  const myHeaders = getHeaders({ siteId })
  const body = JSON.stringify(payload)
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body,
    redirect: 'follow',
  }
  let response = await fetch(url, requestOptions)
  response = await checkStatusCode(response)
  return response
}

export const vacancyApply = () => {
  return new Promise((resolve, reject) => {
    return setTimeout(() => {
      console.warn('Vacancy apply is not yet ready')
      resolve(true)
    }, 1000)
  })
}

export const vacancyOrientationDayApply = () => {
  return new Promise((resolve, reject) => {
    return setTimeout(() => {
      console.warn('Vacancy orientation day apply is not yet ready')
      resolve(true)
    }, 1000)
  })
}

export const submitContactForm = () => {
  return new Promise((resolve, reject) => {
    return setTimeout(() => {
      console.warn('Submit contact form not yet ready')
      resolve(true)
    }, 1000)
  })
}
